.member-profile {
  transition: 0.2s ease-in-out;

  .memeber-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    height: 300px;
    position: relative;
      @media screen and (max-width: 768px) {

      }

    .member-profile-image {
      .profile-image {
        height: 300px;
        width: 300px;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        border-radius: 8px;

        @media screen and (max-width: 768px) {
          width: 180px;
          height: 180px;
        }

      }
    }

    .member-description-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 10;
      width: 100%;
      min-height: 60px;
      background: rgba(0, 0, 0, 0.34);
      padding: 10px 20px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      transition: 0.2s ease-in-out;
      color: white;
      font-weight: bold;

      @media screen and (max-width: 768px) {
        min-height: 80px;
      }

      .member-name {
        font-size: 16px;
        font-weight: 400;
      }

      .job-label {
        font-size: 18px;
        text-align: center;
      }
    }
  }
}