@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Rubik:wght@300&display=swap');

* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  color: #272525;
  font-family: 'Rubik', sans-serif;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 32px;
}

p {
  font-size: 24px;
}

a {
  color: inherit;
  text-decoration: none;
}