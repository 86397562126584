.contact-locations {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.locations-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  align-items: center;
  padding: 100px 0;
}

.location {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  min-width: 100px;
  min-height: 100px;
}

.location-title {
  font-size: 24px;
}

.location-address {
 font-weight: normal;
}

.location i {
  font-size: 52px;
  color:#47b1bf;
}

@media screen and (max-width: 1290px) {

  .location-title {
    font-size: 20px;
  }

  .location-address {
    font-size: 14px;
  }
  .location i {
    font-size: 32px;
  }
}

@media screen and (max-width: 800px) {
  .locations-wrapper {
    display: flex;
    width: 60%;
  }
  .location-title {
    font-size: 16px;
  }

  .location-address {
    font-size: 14px;
  }
  .location-p-number {
    font-size: 12px;
  }
  .location i {
    font-size: 22px;
  }
}