.text-input {
    position: relative;
    flex: 1;

    .textInput-label {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-20px, -50%);
        transition: 0.2s ease-in-out;
    }

    .form-input {
        display: flex;
        align-items: center;
        padding-right: 20px;
    }


    .inp .label {
        position: absolute;
        top: 20px;
        right: 12px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.5);
        font-weight: 500;
        transform-origin: 0 0;
        transform: translate3d(0, 0, 0);
        transition: all 0.2s ease;
        pointer-events: none;
      }
      .inp .focus-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.05);
        z-index: -1;
        transform: scaleX(0);
        transform-origin: left;
      }
      .inp input {
        -webkit-appearance: none;
        -moz-appearance: none;
             appearance: none;
        width: 100%;
        border: 0;
        font-family: inherit;
        padding: 16px 12px 0 12px;
        height: 56px;
        font-size: 16px;
        font-weight: 400;
        background: rgba(0, 0, 0, 0.02);
        box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.3);
        color: #000;
        transition: all 0.15s ease;
      }
      .inp input:hover {
        background: rgba(0, 0, 0, 0.04);
        box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.5);
      }
      .inp input:not(:-moz-placeholder-shown) + .label {
        color: rgba(0, 0, 0, 0.5);
        transform: translate3d(0, -12px, 0) scale(0.75);
      }
      .inp input:not(:-ms-input-placeholder) + .label {
        color: rgba(0, 0, 0, 0.5);
        transform: translate3d(0, -12px, 0) scale(0.75);
      }
      .inp input:not(:placeholder-shown) + .label {
        color: rgba(0, 0, 0, 0.5);
        transform: translate3d(0, -12px, 0) scale(0.75);
      }
      .inp input:focus {
        background: rgba(0, 0, 0, 0.05);
        outline: none;
        box-shadow: inset 0 -2px 0 #0077FF;
      }
      .inp input:focus + .label {
        color: #0077FF;
        transform: translate3d(0, -12px, 0) scale(0.75);
      }
      .inp input:focus + .label + .focus-bg {
        transform: scaleX(1);
        transition: all 0.1s ease;
      }

}