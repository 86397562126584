.about-wrapper {
  width: 80%;
  margin: 0 auto;
  padding: 30px 0;
}

.about-ethics {
  padding: 50px 0;
}

.about-header {
  font-size: 52px;
  text-align: center;
  color: #00B3C1;
  font-weight: bolder;
}

.about-text {
  font-size: 28px;
  padding: 20px 0;
  line-height: 1.5em;
  
}

.about-vision {
  padding: 50px 0;
}

.about-main-text .main-text {
  font-weight: bolder;
}


@media screen and (max-width: 992px) {
  .about-wrapper {
    width: 90%;
  }
  .about-header {
    font-size: 42px
  }
  .about-text { 
    font-size: 24px;
    
  }
}