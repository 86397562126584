.contact-mail {
  flex: 1;
  padding: 0 12px;
}

.form_contactus {
  display: flex;
  gap: 6px;
  min-width: 50%;
}

@media screen and (max-width: 1290px) {
  .cp_title {
    font-size: 26px;
  }
}
