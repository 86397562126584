.services {
    width: 80%;
    margin: 0 auto;
    padding: 100px 0;

    @media screen and (max-width: 718px) {
      padding: 40px 0;
    }
  }
  
  #services_title {
    text-align: center;
    font-size: 52px;
    font-weight: bold;
  }
  
  .info_boxes_container {
    display: flex;
    flex-direction: column;
    padding: 50px 0;
  }
  
  .info_box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding:0px 20px;
    opacity: 0;
    transition: 0.2s ease-in-out;
  }
  
  .info_box:nth-child(even) {
    flex-direction: row-reverse;
  }
  
  .info_box_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }
  .info-box-enter-animation {
    opacity: 1;
  }
  .info_box_img {
    flex: 1;
    .tech-img {
      filter: sepia(300%) hue-rotate(150deg) saturate(450%);
    }
    .AI-img {
      filter: grayscale(50%)
    }
  }
  
  .info_box_img img {
    max-width: 100%;
    max-height: 100%;
  }
  
  .info_box h2 {
    color: #47b1bf;
    font-size: 36px;
    padding-bottom: 20px;
  }
  
  @media screen and (max-width: 1200px) {
    .info_box h2 {
      font-size: 26px;
    }
    .info_box_content p {
      font-size: 20px
    }
  }
  
  @media screen and (max-width: 900px) {
    .info_box {
      flex-direction: column;
      padding: 20px 0;
    }
    .info_box:nth-child(even) {
      flex-direction: column;
    }
  
  }