.about-hero {
    position: relative;
  }
  
  .about-hero-container { 
    width: 100%;
    height: 80vh;
    background: url('../../../assets/covers/about_cover.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .about-banner-text {
    position: relative;
    text-align: left;
    width: 100%;
    left: 10%;
    top: 50%;
    font-size: 72px;
    color: black;
    font-weight: bolder;
  }
  
  .about-banner-text__mobile {
    display: none;
  }
  @media screen and (max-width:1000px) {
    .about-banner-text {
      display: none;
    }
  .about-banner-text__mobile {
    display: flex;
    justify-content: center;
  }
  }
  
  @media screen and (max-width: 800px) {
    .about-hero-container {
      background: url('../../../assets/covers/big_cover_about_mobail.png');    
      background-position: 0%;
    }
  }