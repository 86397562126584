.clients-logos {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 80px 20px;

  .clients-logo-title {
    font-size: 52px;
    font-weight: bold;
    padding-bottom: 22px;
    @media screen and (max-width: 800px) {
      padding-bottom: 42px;
    }
  }

  @media screen and (max-width: 800px) {
    padding: 0;
  }

  @mixin white-gradient {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  $animationSpeed: 40s;

  // Animation
  @keyframes scroll {
    0% {
      transform: translateX(250px);
    }
    100% {
      transform: translateX(calc(250px * 7));
    }
  }

  // Styling
  .slider {
    background: white;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 90%;
    padding-bottom: 40px;

    @media screen and (max-width: 800px) {
      width: 100%;
    }

    &::before,
    &::after {
      @include white-gradient;
      content: "";
      height: 300px;
      position: absolute;
      width: 300px;
      z-index: 2;

      @media screen and (max-width: 800px) {
        height: 100px;
        width: 200px;
      }
    }

    &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }

    &::before {
      left: 0;
      top: 0;
    }

    .slide-track {
      animation: scroll $animationSpeed linear infinite;
      display: flex;
      gap: 100px;
      width: calc(300px * 14);
      @media screen and (max-width: 1200px) {
        gap: 20px;
      }
    }

    .slide {
      width: 300px;

      img {
        object-fit: contain;
        height: 300px;
        width: 300px;

        @media screen and (max-width: 800px) {
          height: 100px;
          width: 200px;
        }
      }
    }
  }
}
