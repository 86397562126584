.clients-talk {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1218px;
    margin: 0 auto;
    padding: 160px 30px 100px 65px;
    overflow: hidden;

    .clients-talk-header {
        display: flex;
        flex-direction: column;
        font-size: 52px;
        font-weight: bold;
        padding-bottom: 60px;
  
    }
    .clients-talk-comments {
        display: flex;
        flex-direction: column;
        padding-top: 24px;
        gap: 32px;
        width: 100%;
        
        .comment-show-animation {
            transform: translateX(0%) !important;
            opacity: 1 !important;
        }
        .client-comment-card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            flex: 1;
            width: 80%;
            min-height: 200px;
            padding: 12px;
            position: relative;
            transition: 0.2s ease-in-out;
            background-color: rgba(255, 255, 255, 0.5);
            opacity: 0.8;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
            border-radius: 10px;
            .decorator {
                @media screen and (max-width: 1000px) {
                    display: none;
                }
                position: absolute;
                left: 0;
                opacity: 0.4;
                > img {
                    width: 500px;
                    height: 200px;
                    object-fit: contain;
                    transform: rotate(90deg);
                }
            }
            &:nth-child(odd) {
                align-self: flex-end;
                transform: translateX(-140%);
            }
            &:nth-child(even) {
                transform: translateX(140%);
            }
            .client-comment-header {
                .client-comment-fullname {
                    font-size: 22px;
                    font-weight: bold;
                    align-self: flex-start;
                }
                .client-comment-job-title {
                    font-size: 14px;
                    color: #7e7e7e;
                }
            }
            .client-comment-description {
            }
            .client-comment-footer {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .client-comment-company {
                    > img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        transform: translate(-55%, -55%);
                        max-width: 100px;
                        max-height: 100px;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}