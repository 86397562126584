.mail-sender {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding: 100px 50px;
    justify-content: center;
    position: relative;
    overflow: hidden;
    gap: 10px;
  
    .mail-sent-animation {
      animation: mailSentAnimation 5s ease-in-out;
    }
    .lottie-animation-container {
      width: 400px;
      height: 400px;
      opacity: 0;
      position: absolute;

      @media screen and (max-width: 1000px) {
        width: 140px;
        height: 140px;
      }
    }
    .half_circle_decoration {
      position: absolute;
      right: 0;
      top: 0%;
      z-index: -1000;
      height: 100%;
      object-fit: contain;

      @media screen and (max-width: 1000px) {
        width: 400px;
        height: 400px;
        top: 10%;
        right: 0%;
        opacity: 0.4;
      }
    }
  }
  
  .contact_text {
    font-size: 62px;
    font-weight: bold;
    text-align: center;
  }
  
  .fnamelname-inputs {
    display: flex;
    gap: 10px;
  }
  .contact_sec {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact_title {
    color: black;
    font-size: 52px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    width: 500px;
  }
  
  // ::-webkit-input-placeholder { /* Edge */
  //   color: #272525;
  //   font-size: 14px;
  // }
  
  // :-ms-input-placeholder { /* Internet Explorer */
  //   color: #272525;
  //   font-size: 14px;
  // }
  
  .textarea {
    background: #c2c2c2;
  }
  
  
  // #message {
  //   height: 200px;
  //   width: 100%;
  //   margin: 10px 0;
  //   border: none;
  //   padding: 10px;
  //   font-family: Verdana, Arial, Helvetica, sans-serif;
  //   font-size: 24px;
  //   outline: none;
  // }
  
  // .form input {
  //   display: flex;
  //   align-items: baseline;
  //   font-size: 24px;
  //   width: 100%;
  //   height: 50px;
  //   outline: none;
  //   margin: 20px 0;
  //   border: none;
  //   background: #c2c2c2;
  // }
  
  .form button {
    padding: 10px;
    background-color: rgb(69, 161, 214);
    border: none;
    font-size: 24px;
    color: white;
    margin-right: auto;
  }
  
  @media screen and (max-width: 1600px) {
    .contact_text {
      font-size: 32px;
    }
  }
  
  @media screen and (max-width: 762px) {
    .home_sec_two {
      flex-direction: column-reverse;
      padding: 100px 10px;
    }
    .contact_text {
      text-align: center;
      width: 100%;
      min-width: none;
    }
  }
  
  @media screen and (max-width: 1000px) {
    .contact_sec {
      display: flex;
      flex-direction: column;
    }
    .contact_sec h1 {
      font-size: 22px;
    }
  
    .form {
      width: 100%;
    }
  
    .form input {
      font-size: 18px;
    }
  
    .form button {
      padding: 14px;
      font-size: 18px;
    }
    #message {
      height: 200px;
    }
  
    .home_sec_two {
      border-radius: 0 0 0 0;
    }
  }

  @keyframes mailSentAnimation {
    0% {
      opacity: 1;
    }
    50% {
      width: 500px;
      height: 500px;
    }
    100% {
      opacity: 0;
    }
  }
  
  