.hero_container {
    position: relative;
    display: flex;
    height: 100vh;
    background: url("../../../assets/covers/home_cover_b.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }
  .hero h1 {
    font-size: 70px;
    text-transform: uppercase;
    margin: 0;
    position: relative;
  }
  .banner-text {
    position: absolute;
    left: 100px;
    top: 36%;
    color: #000;
    font-weight: bolder;
    font-size: 42px;
    text-shadow: 1px 1px 1px 1px black;
    text-align: left;
    font-family: "Arimo", sans-serif;
    text-align: left;
    direction: ltr;
  }
  
  .blued {
    color: #47b1bf;
  }
  
  .sub_text {
    text-align: center;
    font-size: 14px;
    background: rgba(0, 0, 0, 0.13);
    border: 1px solid white;
    border-radius: 10px;
    padding: 10px;
  }
  
  .marked_text {
    font-size: 22px;
    text-align: center;
    background-color: #47b1bf;
    color: white;
    display: inline-block;
    padding: 20px 50px;
    width: 100%;
    transition: 0.2s ease-in-out;

    &.home-page {
      animation: buttonAnimation 2s infinite;
    }
    &:hover {
      border-radius: 40px 0 40px 0;
      cursor: pointer;
    }
  }
  
  .banner-text p {
    padding: 0 80px;
  }
  .home_cover_mobile_img {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .hero_container {
      height: auto;
      background-image: none;
      flex-direction: column;
    }
    .banner-text {
      font-size: 24px;
      text-align: center;
      position: relative;
      padding: 30px 0px;
      left: 0;
      top: 0;
    }
    .marked_text {
      font-size: 18px;
      padding: 15px 24px;
    }
    .home_cover_mobile_img {
      display: block;
      margin: 0 auto;
      max-width: 65%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .hero_container {
      flex-direction: column;
      margin-top: 80px;
      height: auto;
    }
  
    .banner-text {
      position: relative;
    }
  }
  

  @keyframes buttonAnimation {
    0% {
      color: #333;
    }
    50% {
      color: #fff;
    }
    100% {
      color: #333;
    }
  }