.dots-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding:0px 30px;
  flex: 1;
  min-height: 100%;
}

.dots-wrapper img {
  width: 100px;
  padding: 20px 0;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: rgb(190, 190, 190);
  margin: 3px 0;
  padding: auto 0;
}

@media screen and (max-width: 900px) {
  .dots-wrapper {
    flex-direction: row;
    width: 100%;
  }

  .dots-wrapper img {
    width: 50px;
  }
}